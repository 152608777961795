import { Address } from 'viem'

import { DepositNetwork } from './chains'
import { isMainnet } from './env'
import { MarketId } from './markets'
import { CollateralId } from './tokens'

// NOTE: This is the default address we use for the manager contract. It is preferable to use a zero address according to the smart contract team.
export const NEW_MANAGER_ADDRESS = '0x0000000000000000000000000000000000000000'

export type LyraContractAddresses = {
  subaccounts: Address
  standardManager: Address
  deposit: Address
  withdraw: Address
  transfer: Address
  matching: Address
  trade: Address
  markets: Record<
    MarketId,
    {
      option: Address | undefined
      perp: Address | undefined
      portfolioManager: Address | undefined
    }
  >
  accountEntryPoint: Address
  accountFactory: Address
  paymaster: Address
  l1CrossDomainMessengerProxy: Address
  subaccountCreator: Address
  collateral: Record<CollateralId, Address | undefined>
  rfq: Address
  tsaShareHandler: Address
}

export const testnetLyraContractAddresses: LyraContractAddresses = {
  subaccounts: '0xb9ed1cc0c50bca7a391a6819e9cAb466f5501d73',
  standardManager: '0x28bE681F7bEa6f465cbcA1D25A2125fe7533391C',
  deposit: '0x43223Db33AdA0575D2E100829543f8B04A37a1ec',
  withdraw: '0xe850641C5207dc5E9423fB15f89ae6031A05fd92',
  transfer: '0x0CFC1a4a90741aB242cAfaCD798b409E12e68926',
  matching: '0x3cc154e220c2197c5337b7Bd13363DD127Bc0C6E',
  trade: '0x87F2863866D85E3192a35A73b388BD625D83f2be',
  rfq: '0x4E4DD8Be1e461913D9A5DBC4B830e67a8694ebCa',
  markets: {
    [MarketId.ETH]: {
      perp: '0x010e26422790C6Cb3872330980FAa7628FD20294',
      option: '0xBcB494059969DAaB460E0B5d4f5c2366aab79aa1',
      portfolioManager: '0xDF448056d7bf3f9Ca13d713114e17f1B7470DeBF',
    },
    [MarketId.BTC]: {
      perp: '0xAFB6Bb95cd70D5367e2C39e9dbEb422B9815339D',
      option: '0xAeB81cbe6b19CeEB0dBE0d230CFFE35Bb40a13a7',
      portfolioManager: '0xbaC0328cd4Af53d52F9266Cdbd5bf46720320A20',
    },
    [MarketId.SOL]: {
      perp: '0xB8D5cF5CAD52216281B30f2e26F12b2f79035e9f',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.DOGE]: {
      perp: '0x17A52E5A30B851400aF26aAC6CE5A90e30d1f8Fe',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.AAVE]: {
      perp: '0x5c5413680641747Df53989F8cE4c85F79a28bcac',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.ARB]: {
      perp: '0xa509E4F8407189A7a9B680AbeF67AAFcbA46e6BE',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.BNB]: {
      perp: '0x84a60db5BCfCbC55b83b535880A07CacEF3821b0',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.NEAR]: {
      perp: '0x59b4755aEb9aC0bF42E5964ee1C337459Fd6282A',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.OP]: {
      perp: '0x9fCA0D846588D97004A6Eeb0D03FC7540114f3C1',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.PEPE]: {
      perp: '0x9a35A412EDC6BBE38A8F544Eb41f954c23430457',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.SUI]: {
      perp: '0xFeB5b1a4EC5D7035224a62197c67cBfF4A98180f',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.TIA]: {
      perp: '0x4C1b50fBf5f48f166601ACFF964FE8b5C415cf53',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.WIF]: {
      perp: '0x7B53AE3FDd53Ad0C7c7B33Eb8E418aF2A0B4281e',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.WLD]: {
      perp: '0x50996e587524Dc6E14124B5c6BF4cF52040f6760',
      option: undefined,
      portfolioManager: undefined,
    },
  },
  accountEntryPoint: '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789',
  accountFactory: '0x000000893A26168158fbeaDD9335Be5bC96592E2',
  paymaster: '0x5a6499b442711feeA0Aa73C6574042EC5E2e5945', // VerifyingPaymaster with fix token fee
  l1CrossDomainMessengerProxy: '0x28976A1DF6e6689Bfe555780CD46dcFcF5552979',
  subaccountCreator: '0xaA52B531BBB5e9d444Dcb304a442ABDFED27475a',
  collateral: {
    [CollateralId.USDC]: '0x6caf294DaC985ff653d5aE75b4FF8E0A66025928',
    [CollateralId.ETH]: '0x41675b7746AE0E464f2594d258CF399c392A179C',
    [CollateralId.USDT]: '0xB696F009e23B31F6a565E187604e43F5B030b241',
    [CollateralId.BTC]: '0x56543a7A848F630a99EfaFF1177907267afb75d0',
    [CollateralId.WSTETH]: '0x8DCa1bC475fB6008165186061398e318dd3e5f06',
    [CollateralId.WEETH]: '0x063793B0AA25CB7784375E1Fed801505838Ebc44',
    [CollateralId.RSWETH]: '0x6e567dC9f952B361D020A594b88149016D7F3df4',
    [CollateralId.RSETH]: '0x911116D3BC6A16BA7af02cc81490639f2A6e7Da7',
    [CollateralId.DAI]: '0xEaAE25403B28D145f74075a6B4A3844cf8dB5fd2',
    [CollateralId.SDAI]: '0x722fC392cF57f1A941B22238FD60a683C2685Ab9',
    [CollateralId.USDE]: '0x14af1E8d2194c5bCB3405f96dd471676c8Fd0CeA',
    [CollateralId.SUSDE]: '0x5fDB7419E7517085B292741f75F5B14Cd6DcAc47',
    [CollateralId.LBTC]: '0x2b743fec38360310D248ba1870a3C341278Ed41F',
    [CollateralId.EBTC]: '0x8763353C3BF746CC320155FAF15B2129D8362519',
    [CollateralId.CBBTC]: '0xe0Ae57C04a29bcB9083ed021cda4b5E136Fbea9D',
  },
  tsaShareHandler: '0x05bf05e4D19DaF3234d3aE287B32212924b551Fe',
}

export const mainnetLyraContractAddresses: LyraContractAddresses = {
  accountEntryPoint: '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789',
  accountFactory: '0x000000893A26168158fbeaDD9335Be5bC96592E2',
  paymaster: '0xa179c3b32d3eE58353d3F277b32D1e03DD33fFCA',
  matching: '0xeB8d770ec18DB98Db922E9D83260A585b9F0DeAD',
  subaccounts: '0xE7603DF191D699d8BD9891b821347dbAb889E5a5',
  deposit: '0x9B3FE5E5a3bcEa5df4E08c41Ce89C4e3Ff01Ace3',
  withdraw: '0x9d0E8f5b25384C7310CB8C6aE32C8fbeb645d083',
  transfer: '0x01259207A40925b794C8ac320456F7F6c8FE2636',
  standardManager: '0x28c9ddF9A3B29c2E6a561c1BC520954e5A33de5D',
  rfq: '0x9371352CCef6f5b36EfDFE90942fFE622Ab77F1D',
  markets: {
    [MarketId.ETH]: {
      perp: '0xAf65752C4643E25C02F693f9D4FE19cF23a095E3',
      option: '0x4BB4C3CDc7562f08e9910A0C7D8bB7e108861eB4',
      portfolioManager: '0xe7cD9370CdE6C9b5eAbCe8f86d01822d3de205A0',
    },
    [MarketId.BTC]: {
      perp: '0xDBa83C0C654DB1cd914FA2710bA743e925B53086',
      option: '0xd0711b9eBE84b778483709CDe62BacFDBAE13623',
      portfolioManager: '0x45DA02B9cCF384d7DbDD7b2b13e705BADB43Db0D',
    },
    [MarketId.SOL]: {
      perp: '0xF00a5bD70772A4CD9a25fa856238962eC5Ad5326',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.DOGE]: {
      perp: '0xF2eA523DB4f21C597C5b0b64316192367C24c367',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.AAVE]: {
      perp: '0x4b6EbaA6cb3c6dA4Cb9b2474216794966Aba826f',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.ARB]: {
      perp: '0xCE081Cf1ed7f41Db1822c69E9d4A2746071C8d9D',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.BNB]: {
      perp: '0xa7C846245A24928183092231D10FCa68506629A1',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.NEAR]: {
      perp: '0x630Fcb229f6C28c428579B39BFD40Ba793Caf009',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.OP]: {
      perp: '0x6515dfB4eaD8d3D838c342B842f098A4A5B37642',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.PEPE]: {
      perp: '0xb3573E11a3dFB6AaA6a761086943b5F1BBA311d9',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.SUI]: {
      perp: '0x63A61e914c7D2a5E0BB593De4369d004C418ECdd',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.TIA]: {
      perp: '0x3fD90C3d4C12190AF6C4431b87Aca06Ed5178C87',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.WIF]: {
      perp: '0x84715f8BD3A0bab44aD357288ABD233cC833e3F0',
      option: undefined,
      portfolioManager: undefined,
    },
    [MarketId.WLD]: {
      perp: '0xae6b2ACb22053E3541DE86a4c0420e5063655AFB',
      option: undefined,
      portfolioManager: undefined,
    },
  },
  trade: '0xB8D20c2B7a1Ad2EE33Bc50eF10876eD3035b5e7b',
  l1CrossDomainMessengerProxy: '0x5456f02c08e9A018E42C39b351328E5AA864174A',
  subaccountCreator: '0xa44e56A6F53b2E27278B468783fe9de6b037e147',
  collateral: {
    [CollateralId.USDC]: '0x57B03E14d409ADC7fAb6CFc44b5886CAD2D5f02b',
    [CollateralId.ETH]: '0xE201fCEfD4852f96810C069f66560dc25B2C7A55',
    [CollateralId.BTC]: '0x7da2D398dddDfC946Efd2C758c4688D21887790d',
    [CollateralId.USDT]: '0x5E72430EC945CCc183c34e2860FFC2b5bac712c2',
    [CollateralId.WSTETH]: '0x69c7AADFfD607F74eE610f047f552CaDecabca4E',
    [CollateralId.WEETH]: '0xF30EE744fCfd135A135E6a4e327e01d0f697e6Ec',
    [CollateralId.RSWETH]: '0xef2Fc00B7F7c71c73a68dAD25c9D673b2e1983ba',
    [CollateralId.RSETH]: '0x35fdB6e79c05809ba6Dc3B2EF5FF7D0BB5D75020',
    [CollateralId.DAI]: '0x67bB0B7c87Df9C5C433ac7eCADfa7396A2927fcF',
    [CollateralId.SDAI]: '0x84f25FB708431F2C2B8596CD30f1f1d94D67f095',
    [CollateralId.USDE]: '0x028B9fFA86fc4c366e11AA8b3E71dc0502713ABF',
    [CollateralId.SUSDE]: '0x375804CdcF0D534FDD2657584A7c4Ff5AB14A2bb',
    [CollateralId.LBTC]: '0xeaF03Bb3280C609d35E7F84d24a996c7C0b74F5f',
    [CollateralId.EBTC]: '0x95FE344A0f420A7aC1B1E69CB1474179a40db882',
    [CollateralId.CBBTC]: '0xC1efE4c45B79250A29A5Dc66cCADc60DB0FcEEe8',
  },
  tsaShareHandler: '0x4B759054CF4DA826e48645EfaDEfE9AfA613FDc5',
}

export const lyraContractAddresses = isMainnet
  ? mainnetLyraContractAddresses
  : testnetLyraContractAddresses

export const USDC_DECIMALS = 6
export const ONE_INCH_ORACLE_ETHEREUM_MAINNET_ADDRESS = '0x07D91f5fb9Bf7798734C3f606dB065549F6893bb'
export const USDC_ETHEREUM_MAINNET_ADDRESS = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
export const MAINNET_LYRA_TOKEN_ADDRESS = '0x01ba67aac7f75f647d94220cc98fb30fcc5105bf'
export const MAINNET_STAKED_LYRA_TOKEN_ADDRESS = '0xCb9f85730f57732fc899fb158164b9Ed60c77D49'
export const OPTIMISM_LYRA_TOKEN_ADDRESS = '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb'
export const ARBITRUM_LYRA_TOKEN_ADDRESS = '0x079504b86d38119F859c4194765029F692b7B7aa'
// stkLYRA
export const ETHEREUM_STK_LYRA_TOKEN_ADDRESS = '0xcb9f85730f57732fc899fb158164b9ed60c77d49'
export const OPTIMISM_STK_LYRA_TOKEN_ADDRESS = '0x0F5d45a7023612e9e244fe84FAc5fCf3740d1492'
export const OPTIMISM_OLD_STK_LYRA_TOKEN_ADDRESS = '0xdE48b1B5853cc63B1D05e507414D3E02831722F8'
export const ARBITRUM_STK_LYRA_TOKEN_ADDRESS = '0x5B237ab26CeD47Fb8ED104671819C801Aa5bA45E'

type ExternalContractAddresses = {
  lyraStakingSink: {
    [DepositNetwork.Optimism]: Address
    [DepositNetwork.Arbitrum]: Address
    [DepositNetwork.Ethereum]: Address
  }
  multiDistributor: Address
}
export const externalContractAddresses: ExternalContractAddresses = {
  lyraStakingSink: {
    [DepositNetwork.Optimism]: '0x1cbbC18CB128AA470733eD29938Ab4878B0BEF20',
    [DepositNetwork.Arbitrum]: '0xea8c58aADfAfA579Df6443EDE307199FFfE0320B',
    [DepositNetwork.Ethereum]: '0x7Fa4ee12BCbe3A7E087839b49a6db96570DE4197',
  },
  multiDistributor: '0x3BB38b77a266Fceb4FA5659e0eCb5ecF6AeAC28D',
}

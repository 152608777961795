import {
  OrderResponseSchema,
  PrivateOrderParamsSchema,
  TriggerPrice1,
  TriggerType1,
} from '@lyra/core/api/types/private.order'
import { PrivateOrderQuoteResultSchema } from '@lyra/core/api/types/private.order_quote'
import { SECONDS_IN_DAY } from '@lyra/core/constants/time'

import { MarketId, marketsConfig } from './markets'
import { CollateralId, CurrencyId } from './tokens'

export enum TimeInForce {
  GoodTilCancelled = 'gtc',
  PostOnly = 'post_only',
  FillOrKill = 'fok',
  IoC = 'ioc',
}

export enum OrderType {
  Limit = 'limit',
  Market = 'market',
}

export type OrderParams = PrivateOrderParamsSchema

export type Order = OrderResponseSchema

export type OpenOrder = Order & { order_status: 'open' | 'untriggered' }
export type ClosedOrder = Order & { order_status: 'filled' | 'cancelled' | 'expired' }

export type Orders = OrderResponseSchema[]

export type OrderParamsWithoutSignature = Omit<
  PrivateOrderParamsSchema,
  'signer' | 'signature' | 'nonce'
>

export type Quote = PrivateOrderQuoteResultSchema

export type TriggerType = TriggerType1
export type TriggerPrice = TriggerPrice1

export const PERPS_LEVERAGE_STEP_SIZE = 0.01
export const PERPS_MAX_LEVERAGE = 15
export const DEFAULT_ORDER_EXPIRY_DAYS = 28
export const MAX_ORDER_EXPIRY_DAYS = 89
export const MAX_ORDER_EXPIRY_SECS = MAX_ORDER_EXPIRY_DAYS * SECONDS_IN_DAY
export const SLIPPAGE_STEP = 0.01 // max 4 sig figs, e.g. 22.22%

export const PERPETUAL_FUTURES_TAKER_FEES = {
  TIER_11: 0.0006,
}

export const OPTIONS_TAKER_FEES = {
  TIER_11: 0.0004,
}

// TO DO: NEEDS VERIFICATION ON MULTIPLIER FOR COLLATERALS @mypalvikram
export const MAX_FEE_MULTIPLIER: Record<CurrencyId, number> = {
  [MarketId.BTC]: 5,
  [MarketId.ETH]: 5,
  [MarketId.SOL]: 5,
  [MarketId.DOGE]: 5000, // NOTE: DOGE has a higher fee multiplier due to min 1000 DOGE trade size
  [MarketId.AAVE]: 5,
  [MarketId.ARB]: 5,
  [MarketId.BNB]: 5,
  [MarketId.NEAR]: 5,
  [MarketId.OP]: 5,
  [MarketId.PEPE]: 5000000,
  [MarketId.SUI]: 5,
  [MarketId.TIA]: 5,
  [MarketId.WIF]: 5,
  [MarketId.WLD]: 5,
  [CollateralId.DAI]: 5,
  [CollateralId.LBTC]: 5,
  [CollateralId.RSETH]: 5,
  [CollateralId.RSWETH]: 5,
  [CollateralId.SDAI]: 5,
  [CollateralId.SUSDE]: 5,
  [CollateralId.USDC]: 5,
  [CollateralId.USDE]: 5,
  [CollateralId.USDT]: 5,
  [CollateralId.WEETH]: 5,
  [CollateralId.WSTETH]: 5,
  [CollateralId.CBBTC]: 5,
  [CollateralId.EBTC]: 5,
}

export const MARK_PRICE_FEE_RATE_CAP = 0.125

export const MIN_TRADE_SIZE: Record<MarketId, number> = Object.entries(marketsConfig).reduce(
  (acc, [marketId, { minTradeSize }]) => {
    acc[marketId as MarketId] = minTradeSize
    return acc
  },
  {} as Record<MarketId, number>
)

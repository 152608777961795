export const roundToStep = (value: number, step: number): number => {
  if (step === 0) {
    return value
  }

  const getDecimalPlaces = (num: number): number => {
    const numStr = num.toString().toLowerCase()
    if (numStr.includes('e')) {
      const [base, exponent] = numStr.split('e')
      const decimalPlacesInBase = (base.split('.')[1] || '').length
      const exponentValue = parseInt(exponent, 10)
      return Math.max(0, decimalPlacesInBase - exponentValue)
    } else {
      return (numStr.split('.')[1] || '').length
    }
  }

  const stepDecimalPlaces = getDecimalPlaces(step)
  const scale = 1 / step
  let rounded = Math.round(value * scale) / scale

  rounded = parseFloat(rounded.toFixed(stepDecimalPlaces))

  return rounded
}

export function countDecimals(num: number): number {
  let numStr = num.toString()

  if (numStr.toLowerCase().includes('e')) {
    const fixedStr = num.toFixed(20)
    numStr = fixedStr.replace(/0+$/, '')
  }

  const index = numStr.indexOf('.')
  if (index === -1) return 0
  return numStr.length - index - 1
}

import { OrderbookInstrumentNamePublisherDataSchema } from '@lyra/core/api/types/channel.orderbook.instrument_name'
import { InstrumentTickerSchema } from '@lyra/core/api/types/channel.ticker.instrument_name.interval'
import { TradePublicResponseSchema } from '@lyra/core/api/types/channel.trades.instrument_name'
import { InstrumentPublicResponseSchema } from '@lyra/core/api/types/public.get_instruments'
import { TradeSettledPublicResponseSchema } from '@lyra/core/api/types/public.get_trade_history'

export enum InstrumentType {
  Perps = 'perp',
  Options = 'option',
  Spot = 'erc20',
}

export type Boards = {
  [expiry: number]: Board
}

export type Board = {
  expiry: Date
  strikes: {
    [strikePrice: number]: Strike
  }
}

export type Strike = {
  call: Ticker | null
  put: Ticker | null
}

export type Ticker = InstrumentTickerSchema
export type Tickers = Ticker[]
export type Orderbook = OrderbookInstrumentNamePublisherDataSchema
export type Trade = TradePublicResponseSchema
export type Trades = TradePublicResponseSchema[]
export type SettledTrade = TradeSettledPublicResponseSchema
export type SettledTrades = TradeSettledPublicResponseSchema[]
export type Instrument = InstrumentPublicResponseSchema
export type Instruments = InstrumentPublicResponseSchema[]

export type SubscribeState = 'unsubscribed' | 'loading' | 'subscribed' | 'error'
